import classNames from "classnames";
import Link from "next/link";
import { CancelIcon } from "../icons";
import styles from "./Modal.module.scss";

export function Modal({
  returnHref,
  children,
  className,
  onClose,
}: {
  children: React.ReactNode;
  returnHref: string;
  className?: string;
  onClose?: () => void;
}) {
  return (
    <div className={styles.Modal}>
      <div className={classNames("overflow-y-auto", className)}>
        <div>{children}</div>
        <Link href={returnHref} onClick={onClose} scroll={false}>
          <CancelIcon className="m-4 text-4xl stroke-white" />
        </Link>
      </div>
    </div>
  );
}
