"use client";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
const userTz = dayjs.tz.guess();

export function LocalizedDateTime({
  date,
  outputFormat,
}: {
  date?: Date;
  outputFormat: string;
}) {
  if (!date) return "";
  const lDate = dayjs(date).tz(userTz).format(outputFormat);
  return lDate;
}
