"use client";

import { Spinner } from "@cakemembers/components-core";
import classNames from "classnames";
import { motion } from "framer-motion";
import lottie from "lottie-web";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

type LottieInstanceProps =
  | {
      path: string;
      onComplete: () => void;
      specificHeight?: string;
      fillAvailableWidth?: never;
    }
  | {
      path: string;
      onComplete: () => void;
      specificHeight?: never;
      fillAvailableWidth?: boolean;
    };

export function LottieInstance({
  path,
  onComplete,
  specificHeight,
  fillAvailableWidth,
}: LottieInstanceProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [localClassName, setLocalClassName] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 765px)" });
  const animationContainer = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!animationContainer.current) return;

    const handleDataReady = () => {
      setIsLoading(false);

      // In order to get rid of the classname hydration error
      setLocalClassName(
        classNames(
          isMobile
            ? specificHeight
              ? specificHeight
              : fillAvailableWidth
              ? "h-full"
              : "h-[100dvh]"
            : "h-[100dvh]",
          "w-screen flex justify-center items-center"
        )
      );
    };

    const instance = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path,
    });

    instance.addEventListener("complete", onComplete);
    instance.addEventListener("data_ready", handleDataReady);

    return () => {
      instance.removeEventListener("complete", onComplete);
      instance.removeEventListener("data_ready", handleDataReady);
      instance.destroy();
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="relative grow h-full flex flex-col items-center justify-center"
      ref={wrapperRef}
    >
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <div className={localClassName} ref={animationContainer} />
    </motion.div>
  );
}
