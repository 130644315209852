"use client";
import classNames from "classnames";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import styles from "./AppHeader.module.scss";
import { useEffect, useState } from "react";

export function AppHeaderLink({
  children,
  ...props
}: React.ComponentProps<typeof Link>) {
  const pathname = usePathname();
  const sp = useSearchParams();
  const [changing, setChanging] = useState(false);

  useEffect(() => {
    let fullHref = pathname;
    if (sp.has("view")) {
      fullHref = `${pathname}?view=${sp.get("view")}`;
    }
    if (props.href === fullHref && changing) {
      setChanging(false);
      document.getElementById("side-menu")?.click();
    }
  }, [pathname, sp, changing]);

  function handleClick() {
    setChanging(true);
  }

  let isActive = pathname.startsWith(props.href.toString());
  if (sp.has("view")) {
    isActive = sp.get("view") === props.href.toString().split("?view=")[1];
  }

  return (
    <Link
      {...props}
      className={classNames(props.className, {
        [styles.active]: isActive,
      })}
      onClick={handleClick}
    >
      {props.title}
    </Link>
  );
}
