import classNames from "classnames";
import React from "react";

export type CheckboxProps = React.ComponentPropsWithoutRef<"input"> & {
  label: React.ReactNode;
  icon?: React.ReactNode;
  helperText?: React.ReactNode;
  wrapperClassName?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const { label, helperText, wrapperClassName, ...inputProps } = props;
    return (
      <div className={classNames("flex items-start gap-2", wrapperClassName)}>
        <input
          ref={ref}
          type="checkbox"
          value="on"
          className="w-4 h-4 text-primary bg-gray-100 accent-primary border-gray-300 rounded focus:ring-primary focus:ring-2"
          {...inputProps}
        />
        <div className="ms-2 text-sm">
          <label htmlFor="helper-checkbox" className="font-medium">
            {label}
          </label>
          {helperText && (
            <p id="helper-checkbox-text" className="text-xs font-normal">
              {helperText}
            </p>
          )}
        </div>
      </div>
    );
  }
);
